import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allContentfulGalleryImage {
        edges {
          node {
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            shortDescription {
              shortDescription
            }
          }
        }
      }
    }
  `);
  console.log(data);
  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Gallery</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner photo-grid">
            {data.allContentfulGalleryImage.edges.map((img, i) => (
              <span className="image fit">
                <Img
                  style={{ marginBottom: '0.25em' }}
                  fluid={img.node.image.fluid}
                />
                {img.node.shortDescription.shortDescription}
              </span>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default GalleryPage;
